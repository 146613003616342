import {ElementType} from 'react';

import Button from '@mui/material/Button';
import {OverridableStringUnion} from '@mui/types';

import AppText from '../appTexts';
import './index.scss';
import {CircularProgress} from '@mui/material';

interface ButtonPropsVariantOverrides {}

interface IProps {
  text?: string;
  children?: JSX.Element | string | Element[];
  onClick?: (pramas?: any) => void;
  cssClass?: string;
  width?: string;
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  startIcon?: React.ReactNode;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  textColor?: string;
  textType?: 'text2' | 'title' | 'subTitle' | 'text1' | 'cardTitle';
  component?: ElementType<any>;
  disabled?: boolean;
}

const AppButton: React.FC<IProps> = ({
  text,
  variant = 'contained',
  onClick,
  color,
  startIcon,
  loading = false,
  type,
  cssClass,
  width,
  textColor,
  textType = 'text2',
  children,
  component,
  disabled = false,
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      color={color}
      startIcon={startIcon}
      type={type}
      className={` app-button ${
        variant === 'contained' ? 'hover-contained' : ''
      } ${cssClass}`}
      style={{width: `${width}`}}
      component={component}
      disabled={disabled || loading}>
      <>
        {loading && <CircularProgress size={20} className="button-loading" />}

        <AppText type={textType} text={text} color={textColor} />
        {children}
      </>
    </Button>
  );
};

export default AppButton;

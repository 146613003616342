// requestDetailsSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  open: false,
  messsage: '',
};

const errorModalSlice = createSlice({
  name: 'errorModalSlice',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.messsage = action.payload;
    },
    closeModal: state => {
      state.open = false;
      state.messsage = '';
    },
  },
});

export const {openModal, closeModal} = errorModalSlice.actions;

export default errorModalSlice.reducer;

import {combineReducers} from '@reduxjs/toolkit';
import commonSlice from '../features/commonSlice';
import authSlice from '../features/authSlice';
import counterSlice from '../features/counterSlice';
import createAccountSlice from '../features/createAccountSlice';
import localSlice from '../features/localSlice';
import addRequestSlice from '../features/addRequestSlice';
import filtersRequestsListSlice from '../features/filtersRequestsListSlice';
import buyerBidsSlice from '../features/buyerBidsSlice';
import requestDetailsSlice from '../features/requestDetailsSlice';
import myBidSlice from '../features/myBidSlice';
import notificationsSlice from '../features/notificationsSlice';
import errorModalSlice from '../features/errorModalSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  counter: counterSlice,
  local: localSlice,
  createAccount: createAccountSlice,
  common: commonSlice,
  addRequest: addRequestSlice,
  filters: filtersRequestsListSlice,
  buyerBids: buyerBidsSlice,
  requestDetails: requestDetailsSlice,
  myBid: myBidSlice,
  notifications: notificationsSlice,
  errorModal: errorModalSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import {Warning} from '../../../assets/svg';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {closeModal} from '../../../store/features/errorModalSlice';
import MessageModal from '../messageModal';

const WarningModal = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {messsage, open} = useAppSelector(s => s.errorModal);
  return (
    <MessageModal
      handelClose={() => dispatch(closeModal())}
      visiable={open}
      Icon={<Warning />}
      title="Oops!"
      subTitle={messsage}
      buttonText={t('global.done')}
      background={'#FFF9EC'}
      onClick={() => {
        dispatch(closeModal());
      }}
    />
  );
};

export default WarningModal;

import {createSlice} from '@reduxjs/toolkit';
import {User} from 'oidc-client-ts';

interface State {
  redirect?: string;
  accountData?: Omit<User, 'session_state' | 'scope'>;
}
export const initialState: State = {
  redirect: '',
  accountData: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOutSuccess: state => {
      state.redirect = '/';
    },

    signInSuccess: (state, action) => {
      state.accountData = action.payload;
    },
  },
});

export const {signOutSuccess, signInSuccess} = authSlice.actions;

export default authSlice.reducer;

import {DialogActions, DialogContent} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppText from 'src/components/atoms/appTexts';
import AppButton from 'src/components/atoms/button/Button';
import './index.scss';
interface IProps {
  handelClose: (reason?: string) => void;
  visiable: boolean;
  Icon: JSX.Element;
  title: string;
  subTitle: string;
  buttonText: string;
  onClick?: () => void;
  hint?: string;
  background?: string;
}

const MessageModal: React.FC<IProps> = ({
  handelClose,
  visiable,
  Icon,
  title,
  subTitle,
  buttonText,
  onClick,
  hint,
  background,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={visiable}
      onClose={(_, reason) => {
        handelClose(reason);
      }}
      className="message-modal">
      <DialogContent id="alert-dialog-title">
        <div className="icon" style={{background: `${background}`}}>
          {Icon}
        </div>
        <AppText text={`${title}`} type="title" />
        <AppText text={`${subTitle}`} type="subTitle" />
        {hint && <AppText text={`${hint}`} type="text1" />}
      </DialogContent>
      <DialogActions>
        <AppButton text={`${buttonText}`} onClick={onClick} width="168px" />
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;

import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface Data {
  step: number;
  phoneNumber?: string;
  registerationCode?: string;
  userType?: string;
  expireAtSeconds?: number;
}

interface CreateAccountState {
  stepData: {
    step: number;
    phoneNumber?: string;
    registerationCode?: string;
    userType?: string;
    expireAtSeconds?: number;
  };
  skipDocs: boolean;
}

// Define the initial state using that type
const initialState: CreateAccountState = {
  stepData: {
    step: 0,
    phoneNumber: undefined,
    registerationCode: undefined,
    userType: undefined,
    expireAtSeconds: undefined,
  },
  skipDocs: true,
};

export const createAccountSlice = createSlice({
  name: 'createAccount',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<Data>) => {
      state.stepData = {...state.stepData, ...action.payload};
    },
    setSkipDocs: (state, action: PayloadAction<boolean>) => {
      state.skipDocs = action.payload;
    },
  },
});

export const {setStep, setSkipDocs} = createAccountSlice.actions;

export default createAccountSlice.reducer;

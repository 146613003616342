// requestDetailsSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setNotifictions: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {setNotifictions} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import axios from 'axios';
import {API_BASE_URL} from '../configs/AppConfig';
import {userManager} from 'src/configs/OAuthConfig';
import {store} from '../store/store';
import {openModal} from '../store/features/errorModalSlice';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  httpsAgent: {
    rejectUnauthorized: false,
  },
});

// API Request interceptor
service.interceptors.request.use(async config => {
  const user = await userManager.getUser();

  config.headers['Accept-Language'] = store.getState().local.language;
  if (user) {
    const accessToken = user.access_token;
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// API respone interceptor
service.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      await userManager.removeUser();
      // window.location.reload();
      // window.location.href = '/'; // Redirect to login page
    } else if (
      (error.response.status === 400 &&
        Object.keys(error.response.data.validationErrors).length === 0) ||
      error.response.status === 500
    ) {
      store.dispatch(openModal(error.response.data.supportMessage));
    } // Check for 500 response
    // if (error.response.status === 500) {
    //   // Reject with new Error
    //   return Promise.reject(new Error('Internal server error'));
    // }
    // Just return the error
    return Promise.reject(error);
  },
);

export default service;

import {createSlice} from '@reduxjs/toolkit';

interface State {
  bids: any[];
}
export const initialState: State = {
  bids: [],
};

export const buyerBidsSlice = createSlice({
  name: 'buyerBids',
  initialState,
  reducers: {
    toggleBid: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.bids.findIndex(
        item => item.id === newItem.id,
      );
      if (existingItemIndex !== -1) {
        // If the item already exists, update its count
        const newBids = state.bids.filter(bid => bid.id !== newItem.id);
        state.bids = newBids;
      } else {
        // If the item doesn't exist, add it to the list
        state.bids.unshift(newItem);
      }
    },
    updateBids: (state, action) => {
      state.bids = action.payload;
    },
    clearBids: state => {
      state.bids = [];
    },
  },
});

export const {toggleBid, updateBids, clearBids} = buyerBidsSlice.actions;

export default buyerBidsSlice.reducer;

import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import ProtectedRoute, {AuthRoutes, BetweenRoutes} from './ProtectedRoute';
import useGetLoginUserType from 'src/hooks/useGetLoginUserType';

const AppLayout = React.lazy(
  () => import('src/components/templates/appLayout'),
);
//seller routes
const SellerRequests = React.lazy(
  () => import('../screens/sellerScreens/requests/requestList'),
);
const SellerRequestDetails = React.lazy(
  () => import('../screens/sellerScreens/requests/requestDetails'),
);
const Addbid = React.lazy(
  () => import('../screens/sellerScreens/requests/addbid'),
);
const SellerOrders = React.lazy(
  () => import('../screens/sellerScreens/orders/ordersList'),
);
const SellerOrderDetails = React.lazy(
  () => import('../screens/sellerScreens/orders/orderDetails'),
);
//buyer routes
const Requests = React.lazy(
  () => import('../screens/buyerScreens/requests/requestList'),
);
const AddRequests = React.lazy(
  () => import('../screens/buyerScreens/requests/addRequests'),
);
const BuyerRequestDetails = React.lazy(
  () => import('../screens/buyerScreens/requests/requestDetails'),
);

const BuyerOrders = React.lazy(
  () => import('../screens/buyerScreens/orders/ordersList'),
);
const BuyerOrderDetails = React.lazy(
  () => import('../screens/buyerScreens/orders/orderDetails'),
);
//settings
const Settings = React.lazy(() => import('../screens/settings'));

//landing page routes
const Home = React.lazy(() => import('../screens/landingPageScreens/home'));
const PrivacyPolicy = React.lazy(
  () => import('../screens/landingPageScreens/privacyPolicy'),
);
const ReturnPolicy = React.lazy(
  () => import('../screens/landingPageScreens/returnPolicy'),
);
const TermsService = React.lazy(
  () => import('../screens/landingPageScreens/termsService'),
);
const CarrierOrderDetails = React.lazy(
  () => import('../screens/carrierScreens/orderDetails'),
);
//auth routes
const ResetPassword = React.lazy(() => import('../screens/resetPassword'));
const ForgotPassword = React.lazy(() => import('../screens/forgotPassword'));
const SignIn = React.lazy(() => import('../screens/signin'));
const SignUp = React.lazy(() => import('../screens/signup'));
//docs routes
const UserDocuments = React.lazy(() => import('../screens/userDocuments'));
const PaymentSuccessFeedback = React.lazy(
  () => import('../screens/paymentFeedback/paymentSuccessFeedback'),
);
const PaymentFailedFeedback = React.lazy(
  () => import('../screens/paymentFeedback/paymentFailedFeedback'),
);

const MyRoutes = () => {
  const {userType} = useGetLoginUserType();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <AppLayout />
          </>
        }>
        <Route index element={<Home />} />
        <Route path="terms-service" element={<TermsService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="return-policy" element={<ReturnPolicy />} />
        <Route
          path="carrier/order-details/:id/:deliveryNote?"
          element={<CarrierOrderDetails />}
        />
      </Route>
      {userType === 'Buyer' ? (
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }>
          <Route index element={<Navigate to="/dashboard/requests" />} />
          <Route path="requests" element={<Requests />} />
          <Route path="requests/add-request" element={<AddRequests />} />
          <Route
            path="requests/request-details/:id"
            element={<BuyerRequestDetails />}
          />

          <Route path="/dashboard/orders" element={<BuyerOrders />} />
          <Route path="/dashboard/orders/:id" element={<BuyerOrderDetails />} />
        </Route>
      ) : (
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          }>
          <Route index element={<Navigate to="/dashboard/requests" />} />
          <Route path="requests" element={<SellerRequests />} />
          <Route
            path="requests/request-details/:id"
            element={<SellerRequestDetails />}
          />
          <Route path="requests/:id/addbid" element={<Addbid />} />
          <Route path="/dashboard/orders" element={<SellerOrders />} />
          <Route
            path="/dashboard/orders/:id"
            element={<SellerOrderDetails />}
          />
        </Route>
      )}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <AppLayout />
          </ProtectedRoute>
        }>
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route
        path="/dashboard"
        element={
          <>
            <AppLayout />
          </>
        }>
        <Route
          element={<PaymentSuccessFeedback />}
          path="payment/succeed/:id"
        />
        <Route
          element={<PaymentFailedFeedback />}
          path="payment/cancelled/:id"
        />
      </Route>

      <Route
        element={
          <AuthRoutes>
            <SignIn />
          </AuthRoutes>
        }
        path="/sign-in"
      />
      <Route
        element={
          <AuthRoutes>
            <SignUp />
          </AuthRoutes>
        }
        path="/sign-up"
      />
      <Route
        element={
          <AuthRoutes>
            <ForgotPassword />
          </AuthRoutes>
        }
        path="/forgot-password"
      />
      <Route
        element={
          <AuthRoutes>
            <ResetPassword />
          </AuthRoutes>
        }
        path="/reset-password"
      />
      <Route
        element={
          <BetweenRoutes>
            <UserDocuments />
          </BetweenRoutes>
        }
        path="/user-documents"
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default MyRoutes;

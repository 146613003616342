import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state



// Define the initial state using that type
const initialState = {
  filters: {
    orderNumber:'',
    categories: [],
    addresses: [],
    deliveryTimes: [],
  }
};

export const filtersRequestsListSlice = createSlice({
  name: 'filtersRequestsList',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
  },
});

export const { addFilters } = filtersRequestsListSlice.actions;

export default filtersRequestsListSlice.reducer;

import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

// Define a type for the slice state
interface LocalState {
  language: string;
  dir: string;
}

// Define the initial state using that type
const initialState: LocalState = {
  language: 'en-US',
  dir: 'ltr',
};

export const localSlice = createSlice({
  name: 'local',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      state.dir = action.payload === 'en-US' ? 'ltr' : 'rtl';
    },
  },
});

export const {setLanguage} = localSlice.actions;

export default localSlice.reducer;

// requestDetailsSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const requestDetailsSlice = createSlice({
  name: 'requestDetails',
  initialState,
  reducers: {
    fetchRequestDetailsStarted: state => {
      state.loading = true;
    },
    fetchRequestDetailsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchRequestDetailsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchRequestDetailsStarted,
  fetchRequestDetailsSuccess,
  fetchRequestDetailsFailed,
} = requestDetailsSlice.actions;

export default requestDetailsSlice.reducer;

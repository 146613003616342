import {CircularProgress} from '@mui/material';
import './index.scss';
const AppLoader = () => {
  return (
    <div className="loader-container">
      <CircularProgress />
    </div>
  );
};

export default AppLoader;

import {createSlice, Dispatch} from '@reduxjs/toolkit';

interface State {
  loading: boolean;
  message: string;
  showMessage: boolean;
}
export const initialState: State = {
  loading: false,
  message: '',
  showMessage: false,
};
export const showMessageByAsync = message => (dispatch: Dispatch) => {
  dispatch(showMessage(message));
  setTimeout(() => {
    dispatch(hideMessage());
  }, 5000);
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideMessage: state => {
      state.message = '';
      state.showMessage = false;
    },
    showLoading: state => {
      state.loading = true;
    },
    hidLoading: state => {
      state.loading = false;
    },
  },
});

export const {showMessage, hideMessage, showLoading, hidLoading} =
  commonSlice.actions;

export default commonSlice.reducer;

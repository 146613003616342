import React from 'react';
import './index.scss';

interface IProps {
  type:
    | 'bigTitle'
    | 'title'
    | 'cardTitle'
    | 'subTitle'
    | 'text1'
    | 'text2'
    | 'specialTitle';
  text: string;
  color?: string;
  marginTop?: string;
  marginButtom?: string;
  marginStart?: string;
  marginEnd?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWight?: string;
  cssClass?: string;
}

const AppText: React.FC<IProps> = ({
  type,
  text,
  marginTop,
  marginButtom,
  marginStart,
  marginEnd,
  fontSize,
  lineHeight,
  fontWight,
  cssClass,
  color,
}) => {
  const inlineStyle = {
    color: `${color}`,
    marginTop: `${marginTop}`,
    marginBottom: `${marginButtom}`,
    marginInlineStart: `${marginStart}`,
    marginInlineEnd: `${marginEnd}`,
    fontSize: `${fontSize}`,
    fontWeight: `${fontWight}`,
    lineHeight: `${lineHeight}`,
  };
  switch (type) {
    case 'title':
      return (
        <h3 className={`app-title ${cssClass}`} style={inlineStyle}>
          {text}
        </h3>
      );
    case 'cardTitle':
      return (
        <h3 className={`card-title ${cssClass}`} style={inlineStyle}>
          {text}
        </h3>
      );
    case 'subTitle':
      return (
        <p className={`app-sub-title ${cssClass}`} style={inlineStyle}>
          {text}
        </p>
      );
    case 'text1':
      return (
        <p className={`text1 ${cssClass}`} style={inlineStyle}>
          {text}
        </p>
      );
    case 'text2':
      return (
        <span className={`text2 ${cssClass}`} style={inlineStyle}>
          {text}
        </span>
      );
    case 'bigTitle':
      return (
        <h3 className={`app-bigTitle ${cssClass}`} style={inlineStyle}>
          {text}
        </h3>
      );
    case 'specialTitle':
      return (
        <h3 className={`app-specialTitle ${cssClass}`} style={inlineStyle}>
          {text}
        </h3>
      );
    default:
      return (
        <p className={`text1 ${cssClass}`} style={inlineStyle}>
          {text}
        </p>
      );
  }
};

export default AppText;

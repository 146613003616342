import fetch from '../axios';

export const updateUserInfoService = function (data) {
  return fetch({
    url: '/api/v1/account/update-profile-info',
    method: 'post',
    data: data,
  });
};

export const changeProfileImageService = function (data) {
  return fetch({
    url: '/api/v1/account/change-profile-image',
    method: 'post',
    data: data,
  });
};

export const updateUserPreferredLanguage = function (data) {
  return fetch({
    url: '/api/v1/account/update-preferred-language',
    method: 'post',
    data: data,
  });
};

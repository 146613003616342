import {env} from './EnvironmentConfig';

export const APP_NAME = 'SAYYAD';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const Notifictions_HUB = env.Notifictions_HUB;
export const RealTime_HUB = env.RealTime_HUB;
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `/dashboard`;
export const UNAUTHENTICATED_ENTRY = '/login';
export const PHONE_NUMBER = '+96892103333';

// requestDetailsSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
};

const myBidSlice = createSlice({
  name: 'myBidSlice',
  initialState,
  reducers: {
    fetchMyBidStarted: state => {
      state.loading = true;
    },
    fetchMyBidSuccess: (state, action) => {
      state.loading = false;
      let modifiedItems;
      if (action?.payload) {
        modifiedItems = action?.payload?.items.map(item => {
          return {
            id: item.orderRequestItemId,
            orderRequestItemId: item.orderRequestItemId,
            quantity: item.quantity,
            pricePerQuantity: item.sellerPricePerQuantity,
            mediaFilesIds: item.media,
            totalPricePerQuantity: item.totalPricePerQuantity,
            measuringUnitName: item.measuringUnitName,
            product: item.product,
          };
        });
        state.data = {...action.payload, items: modifiedItems};
      } else {
        state.data = null;
      }
    },
    fetchMyBidFailed: state => {
      state.loading = false;
    },
  },
});

export const {fetchMyBidStarted, fetchMyBidSuccess, fetchMyBidFailed} =
  myBidSlice.actions;

export default myBidSlice.reducer;

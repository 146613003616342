import {Navigate, useLocation} from 'react-router-dom';
import {isAuthenticated, userData} from 'src/configs/OAuthConfig';
// import {store} from '../store/store';

const ProtectedRoute = ({children}) => {
  const location = useLocation();

  if (!isAuthenticated()) {
     // Redirect to sign-in and include the current path as the return URL
     const returnUrl = location.pathname;
     let signInUrl = `/sign-in?returnUrl=${encodeURIComponent(returnUrl)}`;
    return <Navigate to={signInUrl} replace />;
    // return <Navigate to="/sign-in" replace />;
  }
  return children;
};

export default ProtectedRoute;

export const AuthRoutes = ({children}) => {
  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return children;
};

export const BetweenRoutes = ({children}) => {
  const user = userData();
  if (
    isAuthenticated() &&
    user &&
    user.profile.verificationDocumentsCount === '0'
  ) {
    return children;
  }

  return <Navigate to="/dashboard" />;
};
